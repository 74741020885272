<template>
  <div class="root-div">
    <!-- Search -->
    <b-row id="home" class="search-div mx-auto">
      <b-col cols="12" md="6" class="mt-3">
        <p class="m-2 font-bold text-light font-15">Book movers, Location</p>
        <div class="d-flex justify-content-between mx-1 shadow bg-white rounded align-items-center">
          <GmapAutocomplete 
            ref="autocompleteRef"
            class="w-100 bg-white p-5 form-control form-control-solid"
            placeholder="Where Are We Meeting?"
            @change="onChanged"
            @place_changed="onPlaceChanged"
            />
        </div>
      </b-col>
      <b-col cols="12" md="6" class="mt-3" data-app>
        <p class="m-2 font-bold text-light font-15">Moving Date</p>
        <v-btn class="text-capitalize d-flex justify-content-between" color="#FFFFFF" @click="visibleDialog = true" block>
          <span class="font-15">{{ orderInfo.date || 'Select Date' }}</span>
        </v-btn>
        <v-dialog v-model="visibleDialog" width="600px">
          <div class="shadow p-3 mb-3 bg-white rounded">
            <div class="d-flex justify-content-center align-items-center">
              <v-btn fab text small color="grey darken-2" @click="prevMonth">
                <v-icon small>mdi-chevron-left</v-icon>
              </v-btn>
              {{ monthYear }}
              <v-btn fab text small color="grey darken-2" @click="nextMonth">
                <v-icon small>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <v-sheet height="500">
              <v-calendar
                ref="calendar"
                v-model="orderInfo.date"
                class="my-2 shadow"
                :events="events"
                @click:date="setDate"
                @change="updateRange"
              />
            </v-sheet>
          </div>
        </v-dialog>
      </b-col>
      <b-col cols="12" md="12" class="mt-3">
        <p class="m-2 font-bold text-light font-15">Services</p>
        <b-row class="border rounded m-05 p-1" v-if="showService">
          <b-col cols="12" md="6">
            <div class="m-1 round-white w-100 d-flex">
              <span class="mt-3 mr-auto">Movers</span>
              <v-btn icon :disabled="orderInfo.movers < 2" @click="orderInfo.movers--">
                <v-icon>mdi-minus</v-icon>
              </v-btn>
              <span class="mt-3 text-center" style="width: 50px">{{ orderInfo.movers }}</span>
              <v-btn icon :disabled="orderInfo.movers >= getPricingValue('movers')" @click="orderInfo.movers++">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div class="m-1 round-white w-100 d-flex">
              <span class="mt-3 mr-auto">Hours</span>
              <v-btn icon :disabled="orderInfo.hours < 3" @click="orderInfo.hours--">
                <v-icon>mdi-minus</v-icon>
              </v-btn>
              <span class="mt-3 text-center" style="width: 50px">{{ orderInfo.hours }}</span>
              <v-btn icon :disabled="orderInfo.hours >= getPricingValue('hours')" @click="orderInfo.hours++">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div class="m-1 round-white w-100 d-flex" style="height: 48.38px">
              <span class="mt-3 mr-auto">Time</span>
              <v-btn icon :disabled="orderInfo.time <= 8" @click="orderInfo.time--">
                <v-icon>mdi-minus</v-icon>
              </v-btn>
              <span class="mt-3 text-center" style="width: 50px">{{ getTimeString(orderInfo.time) }}</span>
              <v-btn icon :disabled="orderInfo.time >= 17" @click="orderInfo.time++">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div class="m-1 round-white w-100 d-flex">
              <span class="mt-3 mr-auto">Truck Needed</span>
              <b-form-radio-group
                v-model="orderInfo.hasTruck"
                :options="truckOptions"
                buttons
                button-variant="outline-primary"
              />
            </div>
          </b-col>
        </b-row>
        <v-btn class="text-capitalize" @click="showService = true" block large v-else>
          <div class="w-100 d-flex justify-content-between">
            <span class="mt-1">Select Required Services</span>
            <img :src="assets.arrowDownPink" :width="25" />
          </div>
        </v-btn>
      </b-col>
      <b-col cols="12" md="12" class="mt-5">
        <v-btn class="text-capitalize" color="#EB4C60" @click="doSearch" block large>
          <img :src="assets.search" :width="20" />
          <span class="font-bold text-white font-15">&nbsp;&nbsp;&nbsp;Search</span>
        </v-btn>
      </b-col>
      <b-col cols="12" md="12" class="mt-10">
        <v-btn class="text-capitalize" color="#EB4C60" @click="$router.push('/boxes')" block large>
          <span class="font-bold text-white font-15">Book Cleaners for your home</span>
        </v-btn>
      </b-col>
    </b-row>
    <!-- Become -->
    <div class="become-div mx-auto my-30 text-center">
      <p class="m-10 font-bold text-light font-30">Become a MOVX Crew Member</p>
      <v-btn class="mx-auto p-2 text-capitalize border rounded" style="width: 200px;" @click="showBecome = !showBecome" outlined>
        <span class="m-5 text-light">Learn More</span>
        <img :src="showBecome ? assets.arrowUp : assets.arrowDown" :width="15" />
      </v-btn>
      <b-row class="m-10" v-if="showBecome">
        <b-col cols="12" md="2" />
        <b-col cols="12" md="5" class="mt-5 text-left">
          <img :src="assets.check" :width="25" />
          <span class="m-1 mr-auto text-light font-15">Moving Experience (Preferred)</span>
        </b-col>
        <b-col cols="12" md="5" class="mt-5 text-left">
          <img :src="assets.check" :width="25" />
          <span class="m-1 text-light font-15">Background Check</span>
        </b-col>
        <b-col cols="12" md="2" />
        <b-col cols="12" md="5" class="mt-5 text-left">
          <img :src="assets.check" :width="25" />
          <span class="m-1 text-light font-15">Reliable Transportion</span>
        </b-col>
        <b-col cols="12" md="5" class="mt-5 text-left">
          <img :src="assets.check" :width="25" />
          <span class="m-1 text-light font-15">Able to lift 20-100 lbs</span>
        </b-col>
      </b-row>
    </div>
    <!-- About Us -->
    <b-row id="about_us" class="other-div mx-auto" v-if="$isMobile()">
      <b-col cols="12" class="text-center">
        <p class="m-5 font-bold font-20 font-pink">Providing Full Range of Transportation Nationwide</p>
        <p class="m-5 font-bold font-30">About Us</p>
        <p class="m-5">Insured and Professional Moving Company, proud to be apart of your journey. We offer the highest quality of Moving with hand picked experienced movers, who are great people not only great movers! The comfortability you deserve during your move. Moving without Moving</p>
        <v-btn class="m-5 p-2 text-capitalize rounded" color="#EB4C60" style="width: 200px;" @click="showAboutUs = !showAboutUs" outlined>
          <span class="m-5">Learn More</span>
          <img :src="showAboutUs ? assets.arrowUpPink : assets.arrowDownPink" :width="15" />
        </v-btn>
        <b-row class="mt-3" v-if="showAboutUs">
          <b-col cols="12" md="6">
            <div class="shadow p-3 mb-3 bg-white rounded">
              <span class="font-bold">• Located: Houston, Texas</span>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div class="shadow p-3 mb-3 bg-white rounded">
              <span class="font-bold">• Five star rated company</span>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div class="shadow p-3 mb-3 bg-white rounded">
              <span class="font-bold">• Over 20yrs of Experience</span>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div class="shadow p-3 mb-3 bg-white rounded">
              <span class="font-bold">• licensed and Insured</span>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" class="mt-10 about-us-img-div" />
    </b-row>
    <b-row id="about_us" class="about-us-div mx-auto" v-else>
      <b-col cols="12" md="5" class="about-us-img-div" />
      <b-col cols="12" md="1" />
      <b-col cols="12" md="6" class="my-10">
        <p class="m-5 font-bold font-15 font-pink">Providing Full Range of Transportation Nationwide</p>
        <p class="m-5 font-bold font-20">About Us</p>
        <p class="m-5">Insured and Professional Moving Company, proud to be apart of your journey. We offer the highest quality of Moving with hand picked experienced movers, who are great people not only great movers! The comfortability you deserve during your move. Moving without Moving</p>
        <v-btn class="m-5 p-2 text-capitalize rounded" color="#EB4C60" style="width: 200px;" @click="showAboutUs = !showAboutUs" outlined>
          <span class="m-5">Learn More</span>
          <img :src="showAboutUs ? assets.arrowUpPink : assets.arrowDownPink" :width="15" />
        </v-btn>
        <b-row class="mt-3" v-if="showAboutUs">
          <b-col cols="12" md="6">
            <div class="shadow p-3 mb-3 bg-white rounded">
              <span class="font-bold">• Located: Houston, Texas</span>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div class="shadow p-3 mb-3 bg-white rounded">
              <span class="font-bold">• Five star rated company</span>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div class="shadow p-3 mb-3 bg-white rounded">
              <span class="font-bold">• Over 20yrs of Experience</span>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div class="shadow p-3 mb-3 bg-white rounded">
              <span class="font-bold">• licensed and Insured</span>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Services -->
    <b-row id="services" class="other-div mx-auto">
      <b-col cols="12">
        <p class="m-5 font-bold font-30 text-center">Services</p>
        <p class="mx-auto text-center">Insured and Professional Moving Company. Proud to be apart of your journey, offering the highest quality of moving. </p>
        <b-row class="mt-20">
          <b-col cols="12" md="6">
            <div class="shadow p-10 mb-3 bg-white rounded">
              <div :class="$isMobile() ? 'text-center m-1' : 'm-1'">
                <img :src="assets.laborOnly" :height="80" />
                <p>&nbsp;</p>
                <span class="font-bold font-25">Labor Only</span>
              </div>
              <p>&nbsp;</p>
              <p><img class="m-1" :src="assets.checkCirclePink" :width="15" />&nbsp;Dolly / Moving Equipment</p>
              <p><img class="m-1" :src="assets.checkCirclePink" :width="15" />&nbsp;Load / Unload or Both</p>
              <p><img class="m-1" :src="assets.checkCirclePink" :width="15" />&nbsp;Assembly & Dissembling of Bed</p>
              <p><img class="m-1" :src="assets.checkCirclePink" :width="15" />&nbsp;Shrink Wrap <b>(additional fee)</b></p>
              <p><img class="m-1" :src="assets.checkCirclePink" :width="15" />&nbsp;We provide the Movers, You provide the Truck</p>
              <p style="height: 22px;">&nbsp;</p>
              <v-btn class="mt-10 text-capitalize" color="#EB4C60" @click="doBook" block large>
                <span class="font-bold text-white font-15">Book Now</span>
              </v-btn>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div class="shadow p-10 mb-3 rounded" style="background-color: #EB4C60;">
              <div :class="$isMobile() ? 'text-center m-1' : 'm-1'">
                <img :src="assets.deliveryTruck" :height="80" />
                <p>&nbsp;</p>
                <span class="font-bold font-25 text-light">Full Service</span>
                <p class="font-15 text-light" v-if="$isMobile()">(Local & Long Distance)</p>
                <span class="font-15 text-light" v-else>&nbsp;(Local & Long Distance)</span>
              </div>
              <p>&nbsp;</p>
              <p><img class="m-1" :src="assets.checkCircle" :width="15" /><span class="text-light">&nbsp;Movers</span></p>
              <p><img class="m-1" :src="assets.checkCircle" :width="15" /><span class="text-light">&nbsp;Full Service Moving truck</span></p>
              <p><img class="m-1" :src="assets.checkCircle" :width="15" /><span class="text-light">&nbsp;Load and Unload</span></p>
              <p><img class="m-1" :src="assets.checkCircle" :width="15" /><span class="text-light">&nbsp;Dolly's/Moving Equipment</span></p>
              <p><img class="m-1" :src="assets.checkCircle" :width="15" /><span class="text-light">&nbsp;Assembly and Dissembling of Bed</span></p>
              <p><img class="m-1" :src="assets.checkCircle" :width="15" /><span class="text-light">&nbsp;Shrink Wrap and Blankets</span></p>
              <v-btn class="mt-10 text-capitalize" @click="doBook" block large>
                <span class="font-bold font-15 font-pink">Book Now</span>
              </v-btn>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Policy -->
    <b-row id="policy" class="mx-auto my-20" style="background-color: black;">
      <b-col cols="12" :class="$isMobile() ? 'text-center' : 'm-1'">
        <p class="mt-20 font-bold text-light font-30 text-center">Policy</p>
        <p class="m-5 text-light text-center">Every MOVX service is protected with Release Value</p>
        <b-row class="other-div mx-auto my-15">
          <b-col cols="12" md="3">
            <div class="shadow p-5 mt-5 mb-3 rounded" style="background-color: #121212; height: 200px;">
              <img :src="assets.calendar" :height="50" />
              <p class="text-light mt-5">Each customer is allowed 1 free reschedule.</p>
              <p class="text-white">(Additional Reschedules 25$ Fee)</p>
            </div>
          </b-col>
          <b-col cols="12" md="3">
            <div class="shadow p-5 mt-5 mb-3 rounded" style="background-color: #121212; height: 200px;">
              <img :src="assets.wallClock" :height="50" />
              <p class="text-light mt-5">The Clock will begin when both Movers arrive.</p>
              <p class="text-white">(15min grace period for customers)</p>
            </div>
          </b-col>
          <b-col cols="12" md="3">
            <div class="shadow p-5 mt-5 mb-3 rounded" style="background-color: #EB4C60; height: 200px;">
              <img :src="assets.alarmClock" :height="50" />
              <p class="text-light mt-5">Overtime will be calculated by 30min block intervals.</p>
            </div>
          </b-col>
          <b-col cols="12" md="3">
            <div class="shadow p-5 mt-5 mb-3 rounded" style="background-color: #121212; height: 200px;">
              <img :src="assets.dollar" :height="50" />
              <p class="text-light mt-5">Over time hours are processed with an added fee of 10$ to every additional hour</p>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Review -->
    <b-row class="other-div mx-auto text-center">
      <b-col cols="12">
        <span class="font-bold font-30">Trusted by</span>
        <span class="font-bold font-30 font-pink">&nbsp;30k&nbsp;</span>
        <span class="font-bold font-30">people</span>
        <carousel
          v-if="$isMobile()"
          class="my-20"
          :per-page="1"
          :autoplay="true"
          :speed="3000"
          :autoplayTimeout="5000"
          :loop="true"
          paginationColor="#C4C4C4"
          paginationActiveColor="#EB4C60"
          >
          <slide v-for="item in reviews" :key="item.photo">
            <div class="p-10 m-3 bg-white rounded" style="height: 250px;">
              <img :src="item.photo" :height="80" />
              <p class="my-5">{{ item.review }}</p>
              <p class="font-bold">{{ item.name }}</p>
            </div>
          </slide>
        </carousel>
        <b-row class="my-20" v-else>
          <b-col cols="12" md="4" v-for="item in reviews" :key="item.photo">
            <div class="shadow p-10 mb-3 bg-white rounded" style="height: 300px;">
              <img :src="item.photo" :height="80" />
              <p class="my-5">{{ item.review }}</p>
              <p class="font-bold">{{ item.name }}</p>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Footer -->
    <KTFooterExtended id="contact_us"></KTFooterExtended>
  </div>
</template>

<style lang="scss" scoped>
  @import "@/assets/sass/pages/main.scss";
</style>
<style lang="scss">
  .v-event {
    background-color: #EB4C60;
    color: white;
    margin: 2px;
    padding: 1px;
  }
</style>

<script>
import { Carousel, Slide } from 'vue-carousel';
import KTFooterExtended from "@/view/layout/footer/FooterExtended.vue";

import { getDateString, getTimeString } from '../../functions';

import arrowUp from '@/assets/image/arrow_up.png';
import arrowDown from '@/assets/image/arrow_down.png';
import arrowUpPink from '@/assets/image/arrow_up_pink.png';
import arrowDownPink from '@/assets/image/arrow_down_pink.png';
import search from '@/assets/image/search.png';
import check from '@/assets/image/check.png';
import checkCircle from '@/assets/image/check_circle.png';
import checkCirclePink from '@/assets/image/check_circle_pink.png';
import deliveryTruck from '@/assets/image/delivery_truck.png';
import laborOnly from '@/assets/image/labor_only.png';
import alarmClock from '@/assets/image/alarm_clock.png';
import calendar from '@/assets/image/calendar.png';
import dollar from '@/assets/image/dollar.png';
import wallClock from '@/assets/image/wall_clock.png';
import people1 from '@/assets/image/people1.png';
import people2 from '@/assets/image/people2.png';
import people3 from '@/assets/image/people3.png';

export default {
  name: 'Main',
  components: {
    KTFooterExtended,
    Carousel,
    Slide
  },
  computed: {
    dateList() {
      return this.$store.state.dateList;
    },
    monthYear() {
      const { start, end } = this;
      if (!start || !end) {
        return '';
      }
      const startMonth = this.monthFormatter(start);
      const startYear = start.year;
      return `${startMonth} ${startYear}`;
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long"
      });
    }
  },
  watch: {
    dateList() {
      this.setEvents();
      this.onDateChanged();
    }
  },
  data() {
    return {
      assets: {
        arrowUp,
        arrowDown,
        arrowUpPink,
        arrowDownPink,
        search,
        check,
        checkCircle,
        checkCirclePink,
        deliveryTruck,
        laborOnly,
        alarmClock,
        calendar,
        dollar,
        wallClock
      },
      truckOptions: [
        { text: 'YES', value: 'YES' },
        { text: 'NO', value: 'NO' }
      ],
      reviews: [
        { photo: people1, name: 'Jenny Wilson', review: '"They called in advance to check to see if they. An come earlier than scheduled and they did. They were professional and listened to my requests. Top notch work!"' },
        { photo: people2, name: 'Robert Fox', review: '"super communicative before and the day of the move, super friendly and got the job done so quick!! would HIGHLY recommend!"' },
        { photo: people3, name: 'Kristin Watson', review: '"I accidentally scheduled the moving help for wrong month and didn\'t realize it untill day of move and they went above and beyond to get me the help needed same day"' }
      ],
      orderInfo: {
        date: '',
        geoPoint: null,
        address: '',
        movers: 1,
        hours: 2,
        time: 12,
        hasTruck: false
      },
      pricingInfo: null,
      start: null,
      end: null,
      events: [],
      visibleDialog: false,
      showService: false,
      showBecome: false,
      showAboutUs: false
    }
  },
  mounted() {
    this.orderInfo.date = getDateString();
    if (this.$store.state.myInfo) {
      this.orderInfo.geoPoint = this.$store.state.myInfo.geoPoint;
      this.orderInfo.address = this.$store.state.myInfo.address;
      this.$refs.autocompleteRef.$el.value = this.orderInfo.address;
    }
    this.setEvents();
    this.onDateChanged();
  },
  methods: {
    getTimeString,
    onChanged(event) {
      this.$refs.autocompleteRef.$el.value = this.orderInfo.address;
    },
    onPlaceChanged(placeData) {
      if (placeData === null || placeData.geometry === null || placeData.geometry.location === null || placeData.formatted_address === null) {
        return;
      }
      const location = JSON.parse(JSON.stringify(placeData.geometry.location));
      this.orderInfo.geoPoint = { latitude: location.lat, longitude: location.lng };
      this.orderInfo.address = placeData.formatted_address;
      this.$refs.autocompleteRef.$el.value = this.orderInfo.address;
    },
    prevMonth() {
      this.$refs.calendar.prev();
    },
    nextMonth() {
      this.$refs.calendar.next();
    },
    setEvents() {
      const events = [];
      this.dateList.forEach(dateInfo => {
        if (dateInfo.blocked) {
          events.push({
            name: 'Blocked',
            start: dateInfo.date,
            end: dateInfo.date
          });
        }
      });
      this.events = events;
    },
    setDate({ date }) {
      const today = getDateString();
      if (date < today) {
        this.$toast.error('Please select the future date.');
        this.orderInfo.date = today;
      } else {
        this.orderInfo.date = date;
        this.visibleDialog = false;
        this.onDateChanged();
      }
    },
    updateRange({ start, end }) {
      this.start = start;
      this.end = end;
    },
    getPricingValue(item) {
      return this.pricingInfo ? this.pricingInfo[item] : 0;
    },
    onDateChanged() {
      const weekday = new Date(this.orderInfo.date + 'T23:59:59').getDay();
      this.pricingInfo = this.$store.state.pricingList.find(element => element.weekday === weekday);
      if (this.events.find(element => element.start === this.orderInfo.date)) {
        this.$toast.error('This date is blocked!');
      }
      if (this.pricingInfo) {
        if (this.orderInfo.movers > this.pricingInfo.movers) {
          this.orderInfo.movers = this.pricingInfo.movers;
        }
        if (this.orderInfo.hours > this.pricingInfo.hours) {
          this.orderInfo.hours = this.pricingInfo.hours;
        }
      }
    },
    doSearch() {
      this.showService = false;
      this.$store.commit('set', ['orderInfo', this.orderInfo]);
      this.doBook();
    },
    doBook() {
      this.$router.push('/booking');
    }
  }
};
</script>
